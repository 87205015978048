.footerSection {
   /* padding: 88px 100px; */
   padding: 20px;
   //   background-color: #4158cf;
   //   height: 500px;
   height: 100%;
   /* gap: 125px; */
   display: flex;
   justify-content: space-between;
   flex-direction: row;
   align-items: baseline;
}

@media screen and (min-width: 768px) {
   .footerAll {
      padding: 88px 100px;
   }
}

.firstRow {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
}
.secondPart {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   width: 610px;
   gap: 26px;
}
.p {
   color: white;
   font-family: "Poppins";
   font-size: 14px;
   font-weight: 500;
   line-height: 28px;
}
.h5 {
   color: #c0f9f8;
   /* margin-top: 26px; */
   font-family: "Poppins";
   font-size: 20px;
   font-weight: 600;
   line-height: 28px;
}
.h7 {
   color: white;
   /* margin-top: 26px; */
   font-family: "Poppins";
   font-size: 14px;
   font-weight: 500;
   line-height: 21px;
}
.h7Contact {
   color: white;
   /* margin-top: 26px; */
   font-family: "Poppins";
   font-size: 14px;
   font-weight: 500;
   line-height: 21px;
   width: 229px;
}
.contactSuportBrandsSection {
   display: flex;
   flex-direction: column;
}
.contactContent {
   display: flex;
   flex-direction: column;
   gap: 26px;
   margin-top: 26px;
}
.contentt {
   gap: 8px;
   display: flex;
   align-items: start;
}
.worldBrand {
   gap: 16px;
   display: flex;
}
.supportBrandContent {
   margin-top: 26px;
   display: flex;
   flex-direction: column;
   gap: 12px;
}
@media screen and (max-width: 430px) {
   .footerSection {
      padding: 3rem 2rem;
   }
   .contentt h7 {
      margin-left: 10px;
   }
   .contentt a {
      margin-left: 10px;
   }
}
@media screen and (max-width: 1344px) {
   .footerSection {
      display: flex !important;
      height: auto;
      flex-wrap: wrap;
   }
   .firstRow {
      display: flex !important;
      flex-wrap: wrap;
   }
   .secondPart {
      display: flex !important;
      flex-wrap: wrap;
      width: 100% !important;
   }
   .h5 {
      margin-top: 2rem;
   }
   .contactSuportBrandsSection {
      display: flex !important;
      flex-wrap: wrap;
   }
   /* .contactContent {
        display: block !important;
      } */
   .contentt {
      display: flex !important;
      flex-wrap: wrap;
   }
   .worldBrand {
      display: flex !important;
      flex-wrap: wrap;

      margin-top: 2rem;
   }
   /* .supportBrandContent {
        display: block !important;
      } */
}
